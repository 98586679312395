.body{
    letter-spacing: -1px;
    font-family: 'Nanum Gothic Coding', monospace;
    color: #c9c9c9;
    font-size: 17px;
    font-weight: bolder;
}
.navbarWrapper{
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.profilePic{
    background-image: url("../images/19325408.jpg");
    background-size: cover;
    width: 140px;
    height: 140px;
    border: 1px solid transparent;
    border-radius: 50%;
}
.links{
    width: 50%;
    text-align: right;
    position: relative;
}
.back{
    position: fixed;
    top: 0;
    background: radial-gradient(#585858, transparent);
    left: 0;
    text-align: center;
    padding: 22px 5px;
    border-radius: 50px;
    z-index: 1;
}
.picwrapper{
    width: 50%;
}
.header-container{
    width: 90%;
    margin-left: 7%;
}
.profileText{
    margin: auto;
    line-height: 48px;
}
.jobs{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 26px;
}
.description{
    font-family: 'Nanum Gothic Coding', monospace;
    line-height: 1.5;
}
.title-anchor{
    color: #00b8ff;
    text-decoration: none;
}
.description .title-anchor{
    color: #009364;
}
.description .title-anchor:hover{
    color: #00b8ff;
}
.job-application{
    letter-spacing: 2px;
    font-weight: 100;
    font-size: unset;
}
.previous-jobs{
    text-align: center;
}
.freelancer{
    background-image: url('../images/freelancer.jpg');
    display: block;
    padding: 35px;
    width: 133px;
    margin-top: 26px;
    background-size: 142px;
    background-repeat: no-repeat;
    background-position: center;
}
.ionian{
    background-image: url('../images/ionio-logo.jpg');
    display: block;
    padding: 47px;
    background-size: 87px;
    background-repeat: no-repeat;
    background-position: center;
}
.microverse{
    background-image: url('../images/microverse.png');
    display: block;
    padding: 59px;
    background-size: 95px;
    background-repeat: no-repeat;
    background-position: center;
}
.prev-icons{
    display: flex;
}
.header-social{
    display: flex;
    justify-content: space-evenly;
    margin: 36px 0 18px 0;
}
.header-social a {
    display: block;
    padding: 30px;
    background-size: 40px;
    background-repeat: no-repeat;
    border-radius: 57px;
    background-position: center;
}
.header-social a:hover{
    background-color: #00b8ff4f;
}
.github-social{
    background-image: url('../images/social/github.jpg');
}
.linkedin-social{
    background-image: url('../images/social/linkedin.png');
}
.twitter-social{
    background-image: url('../images/social/twitter.png');
}
.gmail-social{
    background-image: url('../images/social/gmail.png');
}
.app{
    background-color: #000000ba;
}
#work{
    text-align: center;
    font-size: 4rem;
    font-weight: 100;
}
