html,
body,
#root {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  background-color: white;
  overflow-x: hidden;
}
#root{
  background-image: url('../images/user.jpg');
}
body {
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  color: #d1d1d1;
  font-size: 17px;
}

.wrapper {
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: #f0f0f0;
  overflow: auto;
  justify-content: center;
}

.card {
  border: 8px solid rgb(19, 81, 133);
  width: 76%;
  height: 382px;
  margin: auto;
  background: grey;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  align-items: center;
  display: flex;
  justify-content: center;
}

.card:hover {
  background-color: lightskyblue;
  box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}
.card-opacity{
  position: absolute;
  top: 0;  
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: baseline;
  text-align: center;
  background: transparent;
}
.card-opacity:hover{
  background: #ffffffad;
}
.card-title{
  width: 100%;
  color: white;
  background-color: #125185;
  padding-bottom: 5px;
  top: -2px;
  position: absolute;
}
.my-work{
  margin-top: 27px;
  padding-top: 13px;
}
.card-wrapper{
  text-align: center;
  padding: 40px 0;
}
.link-anchorsOne{
  padding-right: 15%;
}
.link-anchors,.link-anchorsOne{
  color: #797979;
  text-decoration: none;
  font-size: 16px;
}
.link-anchors:hover,.link-anchorsOne:hover{
  color: #00b8ff;
}
.project-description{
  font-weight: bold;
  margin: 18px 50px;
}
.build-with{
  display: flex;
  color: black;
  justify-content: center;
  flex-wrap: wrap;
}
.build-with div{
  margin: 3px 17px;
  padding: 6px;
  background: 
  linear-gradient(#fff,#fff) padding-box,
  linear-gradient(to right, #fff, #2600df) border-box;
  color: #3d3d6c;
  border: 3px solid transparent;
  border-radius:15px;
  display:inline-block;
}
@media only screen and (max-width: 425px){
  .card{
    width: auto;
    height: 260px;
    margin: 0 5%;
  }
}