.footer{
    padding: 47px 0 50px 0;
    text-align: center;
}
.findme{
    display: flex;
    text-decoration: none;
    list-style: none;
    justify-content: space-between;
    width: 305px;
    padding: 0;
    margin: 0 auto;
}
.footer-back{
    text-align: center;
}
.footer h2 a:hover{
    color: #009364;
}
small{
    font-size: 67%;
}